@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';
@import '@/styles/mixins/responsive';

.root {
  position: fixed;
  top: 30px;
  left: 50%;
  z-index: 5;
  display: flex;
  gap: 12px;
  align-items: center;
  width: 600px;
  padding: 8px 16px;
  background-color: $N40;
  border-radius: 12px;
  transform: translate(-50%, 0);
  animation:
    fadein 0.5s,
    fadeout 0.5s 2.5s;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
  }

  @include mobile {
    @include Caption;
    top: 80px;
    width: 90%;
    padding: 16px;
    animation:
      fadein-mobile 0.5s,
      fadeout-mobile 0.5s 2.5s;
  }

  .message {
    @include Body1;
    color: $N80;
    text-align: left;

    @include mobile {
      @include Caption;
    }
  }
}

@keyframes fadein {
  from {
    top: 0;
    opacity: 1;
  }

  to {
    top: 30px;
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    top: 30px;
    opacity: 1;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

/* Mobile-specific animations */

@keyframes fadein-mobile {
  from {
    top: 0;
    opacity: 1;
  }

  to {
    top: 80px; /* Adjusted value for mobile */
    opacity: 1;
  }
}

@keyframes fadeout-mobile {
  from {
    top: 80px; /* Adjusted value for mobile */
    opacity: 1;
  }

  to {
    top: 0;
    opacity: 1;
  }
}
