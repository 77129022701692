$H_Header: 60px;
$H_Footer: 200px;
$H_Mobile_Nav: 78px;
$H_Backtesting_Title: 74px;
$H_Onboarding_Bottom: 96px;

// $H_Mobile_Nav_Default: 64px;

// // MEMO: TB-5511
// $H_Mobile_Nav_iOS: calc(
//   64px + env(safe-area-inset-bottom, 14px)
// ); // Using constant as a fallback
// $H_Mobile_Nav: $H_Mobile_Nav_Default; // Default for non-iOS environments

// @supports (height: constant(safe-area-inset-bottom)) or
//   (height: env(safe-area-inset-bottom)) {
//   $H_Mobile_Nav: $H_Mobile_Nav_iOS;
// }
