@import '@/styles/constants/colors.module';
@import '@/styles/constants/size.module';
@import '@/styles/mixins/textStyle';
@import '@/styles/mixins/responsive';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: $H_Header;
  padding: 0 16px;
  background-color: $N10;

  .left,
  .right {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .left {
    gap: 48px;

    .menu {
      @include Body1;
      display: flex;
      gap: 16px;
      align-items: center;
      width: 100%;
      height: 100%;

      .menuItem {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 8px;
        color: $N80;
        text-align: center;
        word-break: keep-all;

        &.active {
          color: $N80;
          border-top: 4px solid transparent;
          border-bottom: 4px solid $P70;
        }
      }
    }
  }

  .right {
    gap: 24px;
  }
}

.headerRightContents {
  display: flex;
  gap: 24px;
  align-items: center;

  .links {
    display: flex;
    gap: 24px;
    padding-right: 16px;
    border-right: 1px dashed $N40;
  }

  .userInfo {
    display: flex;
    gap: 12px;
    align-items: center;

    .profile {
      width: 100%;
      padding-right: 24px;
      text-align: left;
      cursor: pointer;

      .name {
        @include Body2;
        color: $N80;
      }

      .email {
        @include Caption;
        color: $N80;
      }
    }
  }
}
