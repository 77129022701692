@import '@/styles/constants/colors.module';
@import '@/styles/constants/size.module';
@import '@/styles/mixins/textStyle';

.root {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-shrink: 0;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: $H_Mobile_Nav;
  padding-top: 8px;
  background-color: $N20;
  border-top: 1px solid $N30;
  border-radius: 12px 12px 0 0;

  .navItem {
    width: 100%;

    > a {
      display: flex;
      flex: 1 0 0;
      flex-direction: column;
      gap: 4px;
      align-items: center;
      width: 100%;
      color: $N80;
      @include Caption;
    }
  }

  .activeTab {
    a {
      color: $P70;
    }

    svg path,
    rect {
      stroke: $P70;
    }
  }
}
