@import '@/styles/constants/colors.module';
@import '@/styles/constants/size.module';
@import '@/styles/mixins/responsive';
@import '@/styles/mixins/textStyle';

.root {
  @include Body1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  width: 100%;
  height: $H_Footer;
  padding: 0 20px;
  color: $N50;
  background: $N10;

  .info {
    word-wrap: break-word;
    white-space: normal;
  }

  .policyWrapper {
    display: flex;
    gap: 12px;

    > a {
      width: auto;
    }

    .option {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
