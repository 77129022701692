@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';

.root {
  position: relative;

  button:hover ~ .messageBox {
    display: block;
  }

  .wrap {
    background: none;
  }

  .messageBox {
    position: absolute;
    top: calc(100% + 4px);
    left: 50%;
    z-index: 2;
    display: none;
    width: auto;
    padding: 8px 16px;
    margin: 0 auto;
    color: $N10;
    text-align: center;
    white-space: nowrap;
    background: $N80;
    border-radius: 8px;
    transform: translate(-50%, 0);

    > span {
      @include Body2;
      height: 28px;
    }
  }
}
