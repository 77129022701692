@mixin Display1 {
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.96px;
}

@mixin Display2 {
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: -0.8px;
}

@mixin Headline1 {
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 39.2px */
  letter-spacing: -0.56px;
}

@mixin Headline2 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
}

@mixin Title1 {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 30.8px */
  letter-spacing: -0.44px;
}

@mixin Title2 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 28px */
  letter-spacing: -0.4px;
}

@mixin Label1 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 28.8px */
  letter-spacing: -0.36px;
}

@mixin Label2 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.32px;
}

@mixin Body1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.32px;
}

@mixin Body2 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 23.8px */
  letter-spacing: -0.28px;
}

@mixin Underline {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  text-decoration-line: underline;
  letter-spacing: -0.32px;
}

@mixin Caption {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 19.2px */
  letter-spacing: -0.24px;
}
