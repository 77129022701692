@import '@/styles/constants/size.module';

.root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: calc(100vh - $H_Mobile_Nav);
  padding-bottom: $H_Mobile_Nav;
  overflow-x: hidden;
  overflow-y: auto;

  &.withoutNavigation {
    min-height: 100vh;
  }
}
