@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';
@import '@/styles/mixins/theme';

.root {
  display: flex;
  flex-direction: column;
  gap: 40px;

  // align-items: center;
  width: 1320px;
  padding: 120px 0;
  margin: 0 auto;
}
