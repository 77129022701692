@import '@/styles/constants/colors.module';
@import '@/styles/constants/size.module';

.unsupport {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
  height: calc(100vh - $H_Mobile_Nav);
  overflow: none;
  font-size: 24px;
  color: $N50;
}
