@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';
@import '@/styles/mixins/theme';

.root {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: auto;
  white-space: nowrap;

  &:hover {
    filter: brightness(0.8);
  }

  &:active {
    filter: brightness(0.5);
  }

  // themes - default: secondary1
  &.primary1 {
    color: $N10;
    background-color: $P70;
  }

  &.primary2 {
    color: $P80;
    background-color: $N30;
  }

  &.primary3 {
    color: $P70;
    border: 1px solid $P70;
  }

  &.secondary1 {
    color: $N10;
    background-color: $S60;
  }

  &.secondary2 {
    color: $S60;
    border: 1px solid $S60;
  }

  &.neutral1 {
    color: $N50;
    background-color: $N10;
  }

  &.neutral2 {
    color: $N80;
    background-color: $N30;
  }

  &.neutral3 {
    color: $N10;
    background-color: $N80;
  }

  &.large {
    @include Label1;
    height: 56px;
    padding: 16px 24px;
    border-radius: 12px;
  }

  &.medium {
    @include Label2;
    height: 40px;
    padding: 8px 20px;
    border-radius: 8px;
  }

  &.small {
    @include Body2;
    height: 32px;
    padding: 4px 12px;
    border-radius: 8px;
  }

  &.smallSquare {
    width: 24px;
    height: 24px;
  }

  &.mediumSquare {
    @include Body1;
    width: 28px;
    height: 28px;
  }

  // options
  &.disabled {
    cursor: default;
    opacity: 0.3;

    &:hover {
      filter: none;
    }
  }

  &.fullWidth {
    width: 100%;
  }
}
