@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';

.separator {
  border: 0.5px solid rgba($N80, 20%);
}

.cursorPointer {
  cursor: pointer;
}

.positive {
  color: $S60 !important;
}

.negative {
  color: $P50 !important;
}

@keyframes highlight-change {
  0% {
    text-decoration: none;
    background: transparent;
  }

  50% {
    text-decoration: underline $N50;
    background-color: rgba($N50, 50%);
  }

  100% {
    text-decoration: none;
    background: transparent;
  }
}

.highlightChange {
  animation: highlight-change 0.5s ease-in-out;
}
