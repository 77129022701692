@import '@/styles/constants/colors.module';
@import '@/styles/mixins/textStyle';
@import '@/styles/mixins/theme';

.root {
  .content {
    position: relative;
    width: 100%;
    height: 100%;
  }
}
