@import '@/styles/constants/colors.module';
@import '@/styles/constants/size.module';
@import '@/styles/mixins/textStyle';
@import '@/styles/mixins/responsive';

.root {
  display: flex;
  flex-direction: column;
  gap: 80px;
  align-items: center;
  justify-content: center;
  padding: 80px;
  color: $N50;

  .message1 {
    @include Headline1;
    text-align: center;
  }

  .message2 {
    @include Body1;
    margin-top: 24px;
    text-align: center;
  }

  @include mobile {
    gap: 56px;
    height: calc(100vh - $H_Mobile_Nav);
    padding: 100px 20px;

    .message1 {
      @include Title2;
    }

    .homeButton {
      @include Title1;
    }
  }
}
